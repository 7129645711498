$(document).ready(function () {
	let filtersOpened = $(".filter-picker .bottom").hasClass("d-flex");

	/*======  ACTIONS  ======*/

	/*----------  MOBILE MENU TOGGLE  ----------*/
	$(".offCanvasToggler").click(function (event) {
		event.preventDefault();
		$(".off-canvas-nav").toggleClass("active");
		$(".fullscreen-overlay").toggleClass("active");
		$("html, body").toggleClass("no-scrolling");
	});

	/*----------  FAQ TOGGLES  ----------*/
	$(".faq-header").click(function (event) {
		event.preventDefault();
		const target = $(this).data("faq");
		$(`.faq-content[data-faq="${target}"]`).slideToggle();
	});

	/*======  FILTER PICKER  ======*/

	/*----------  FILTER PICKER TOGGLE  ----------*/


	$(".filter-picker-toggler").click(function (event) {
		event.preventDefault();

		if (filtersOpened == 0) {
			$(".filter-picker .bottom").addClass("d-flex");

			$(this)
				.find("span")
				.text($(this).data('close'));

			$(this).find(".icon").removeClass("fa-chevron-down").addClass("fa-chevron-up");

			filtersOpened = 1;
		} else {
			$(".filter-picker .bottom").removeClass("d-flex");

			$(this)
				.find("span")
				.text($(this).data('open'));

			$(this).find(".icon").removeClass("fa-chevron-up").addClass("fa-chevron-down");

			filtersOpened = 0;
		}

		$('#advanced').val(filtersOpened);
	});

	function buildParameters() {
		$('.filter-picker form').submit();
	}

	$('.filter-picker').on('changed.bs.select', '.selectpicker', function (e, clickedIndex, isSelected, previousValue) {
		buildParameters();
	});

	$('.filter-picker').on('keypress blur', '.filter-picker-search', function (e) {
		if (e.which == 13) {
			e.preventDefault();
			buildParameters();
			return false;
		}
	});

	$('#btw-plichtig-select').change(function () {
		if ($(this).val() == "btw") {
			$("#btw-plichtig").removeClass("d-none");
		} else if ($(this).val() == "particulier") {
			$("#btw-plichtig").addClass("d-none");
		}
	});

	$('#btw_plichtig_select').change(function () {
		if ($(this).val() == "btw") {
			$(".btw-plichtig").removeClass("d-none");
		} else if ($(this).val() == "particulier") {
			$(".btw-plichtig").addClass("d-none");
		}
	});

	$('.search-button').click(function (evt) {
		console.log("clicked");
		if ($('#q').val()) {
			buildParameters();
		}
	});

	/*======  SLIDERS  ======*/

	/*----------  HOME SLIDER  ----------*/

	$(".home-slider .slides").slick({
		slidesToShow: 3,
		slidesToScroll: 1,
		arrows: true,
		prevArrow: $(".home-slider .slider-controls .prev-slide"),
		nextArrow: $(".home-slider .slider-controls .next-slide"),
		dots: false,
		rows: 0,
		responsive: [
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					arrows: false
				}
			}
		]
	});



	$(".home-locations-slider .slides").slick({
		slidesToShow: 3,
		slidesToScroll: 1,
		arrows: true,
		prevArrow: $(".home-locations-slider .slider-controls .prev-slide"),
		nextArrow: $(".home-locations-slider .slider-controls .next-slide"),
		dots: false,
		responsive: [
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					arrows: false
				}
			}
		]
	});

	/*----------  TESTIMONY SLIDER  ----------*/

	$(".testimony-slider .slides").slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		infinite: true,
		dots: false,
		arrows: true,
		prevArrow: $(".testimony-slider .slider-controls .prev-slide"),
		nextArrow: $(".testimony-slider .slider-controls .next-slide")
	});

	/*----------  FULL WIDTH SLIDER  ----------*/

	$(".full-width-slider .slides").slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		infinite: true,
		dots: false,
		arrows: true,
		prevArrow: $(".full-width-slider .slider-controls .prev-slide"),
		nextArrow: $(".full-width-slider .slider-controls .next-slide")
	});

	/*----------  IMAGE HEADER SLIDER  ----------*/

	$('.image-header-slider .slides').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		infinite: true,
		dots: false,
		arrows: false,
		fade: true,
		autoplay: true,
		autoplaySpeed: 2500
	});

	$('.home-header-slider .slides').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		infinite: true,
		dots: false,
		arrows: false,
		fade: true,
		autoplay: true,
		autoplaySpeed: 2500,
		responsive: [
			{
				breakpoint: 576,
				settings: {
					autoplay: false,
				}
			}
		]
	});

	$('.camp-header-slider .slides').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		infinite: true,
		dots: false,
		arrows: false,
		fade: true,
		autoplay: true,
		autoplaySpeed: 2500,
		responsive: [
			{
				breakpoint: 576,
				settings: {
					autoplay: false,
				}
			}
		]
	});

	// ==========[ CARD CLICK ]==========
	$(".card-header").click(function () {
		if ($(this).attr("data-closed") == "true") {
			$(".card-header").attr("data-closed", "true");
			$(".card-header").removeClass("rotated");
			$(this).attr("data-closed", "false");
			$(this).addClass("rotated");
		} else {
			$(this).attr("data-closed", "true");
			$(".card-header").removeClass("rotated");
		}
	});

	/*----------  FLASH MESSAGE  ----------*/
	$.fn.flash = function () {

		var self = this;

		$(self).css({
			'top': ($(self).height() * -1) + 'px',
			'display': 'block',
		}).animate({ 'top': '0' }, 500);

		setTimeout(function () {

			$(self).animate({ 'top': ($(self).height() * -1) + 'px' }, 500, function () {
				$(self).remove();
			});

		}, 5000);

	};

	$('#flash').flash();

	$('.table-mobile').footable();

	// ==========[ TAB BANE SCROLL MOBILE ]==========
	$('.nav-tabs').find('.nav-item').click(function (evt) {
		if ($(window).width() < 768) {
			$([document.documentElement, document.body]).animate({
				scrollTop: $(".tab-content").offset().top
			}, 2000);
		}
	});

	// ==========[ SCROLL TO TOP ]==========
	$(window).on('scroll', function () {
		var scrollTop = $(window).scrollTop();
		if (scrollTop > 100) {
			$('#scroll_to_top').addClass('show');
		} else {
			$('#scroll_to_top').removeClass('show');
		}
	});

	$("#scroll_to_top").click(function (evt) {
		evt.preventDefault();
		$([document.documentElement, document.body]).animate({
			scrollTop: 0
		}, 800);
	});

	$('input[name="geboortedatum"]').flatpickr({
		locale: "nl",
		altInput: true,
		dateFormat: "Y-m-d",
	});

	$('input[date-field="true"]').flatpickr({
		locale: "nl",
		altInput: true,
		dateFormat: "Y-m-d",
	});

	// ==========[ YES NO RADIO CHANGE WRAPPER ]==========
	$(':radio[data-wrapper]').change(function () {
		let wrapper = "#" + $(this).data("wrapper");
		if ($(this).val() == 1) {
			$(wrapper).addClass("d-none");
		} else if ($(this).val() == 2) {
			$(wrapper).removeClass("d-none");
		}
	});

	$("#toestemming_start").next().removeClass("form-control");
	$("#toestemming_einde").next().removeClass("form-control");

	// ==========[ ENQUETE ]==========
	$('.rating').change(function () {
		let questionId = $(this).data("question_id");
		if (questionId != undefined) {
			let ratingVal = $(this).val();
			if (ratingVal < 5) {
				$("#comment_label-" + questionId).html('Gelieve te verduidelijken wat niet goed was *');
			} else {
				$("#comment_label-" + questionId).html("Opmerking");
			}
		}
	});

	$('#submit_enquete').click(function () {
		$('.loader').removeClass("d-none");
		let form = $("#survey");
		let form_data = new FormData(form[0]);
		$.ajax({
			url: form.attr("action"),
			data: form_data,
			enctype: "multipart/form-data",
			dataType: "json",
			type: "post",
			processData: false,
			contentType: false,
			success: function (data) {
				$('.loader').addClass("d-none");
				if (data.success) {
					location.href = '/mijn-account/enquetes/';
				} else {
					// ==========[ ADD ERROR MESSAGES ]==========
					let errors_wrapper = $('.feedback-wrapper');
					errors_wrapper.removeClass("d-none");
					errors_wrapper.empty();
					$(data.errors_messages).each(function (i, val) {
						let error = '<div id="feedback" class="alert alert-dismissible mt-4 alert-danger" role="alert" style="">';
						error += '<span id="feedback-content">' + val + '</span>';
						error += '<button type="button" class="close" data-dismiss="alert" aria-label="Close">';
						error += '<span aria-hidden="true">×</span>';
						error += '</button>';
						error += '</div>';
						errors_wrapper.append(error);
					});

					// ==========[ REMOVE PREVIOUS ERRORS ]==========
					$(form).find('.error-input').each(function () {
						$(this).removeClass('error-input');
					});

					// ==========[ ADD ERROR CLASS TO FIELDS ]==========
					$(data.errors_questions).each(function (i, val) {
						let selector = 'question-' + val;
						$('#' + selector).addClass('error-input');
					});
					$(data.errors_comments).each(function (i, val) {
						let selector = 'comment_label-' + val;
						$('#' + selector).addClass('error-input');
						selector = 'comment_textarea-' + val;
						$('#' + selector).addClass('error-input');
					});

					// ==========[ SCROLL TO FORM TOP ]==========
					$([document.documentElement, document.body]).animate({
						scrollTop: $("#form-block").offset().top
					}, 2000);
				}
			}
		});
	});
});
